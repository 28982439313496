import React, { createContext, useState, useContext, useCallback } from "react";

const SearchContext = createContext();

export function useSearchContext() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const [searchState, setSearchState] = useState({
    searchTerm: "",
    selectedCategory: "",
    lastSearchPage: "/",
  });

  const updateSearchState = useCallback((newState) => {
    setSearchState((prevState) => ({ ...prevState, ...newState }));
  }, []);

  return (
    <SearchContext.Provider value={{ searchState, updateSearchState }}>
      {children}
    </SearchContext.Provider>
  );
}
