import React from "react";
import "./About.css";

function About() {
  return (
    <div className="About">
      <h1>About Job Hunt Malawi</h1>
      <div className="about-content">
        <p>
          Job Hunt Malawi is a leading platform connecting job seekers with
          employers across various industries. Our mission is to simplify the
          job search process and help individuals find meaningful career
          opportunities.
        </p>
        <p>
          Founded in 2024, we're committed to helping people find their dream
          jobs and assisting companies in building strong teams.
        </p>
        <h2>Our Values</h2>
        <ul>
          <li>Transparency in job listings and application processes</li>
          <li>Equal opportunities for all job seekers</li>
          <li>Continuous innovation in job search technology</li>
          <li>
            Building strong partnerships with employers and industry leaders
          </li>
        </ul>
        <p>
          Our team of dedicated professionals works tirelessly to ensure that
          Job Hunt Malawi remains at the forefront of the job search industry,
          providing the best possible experience for both job seekers and
          employers.
        </p>
      </div>
    </div>
  );
}

export default About;
