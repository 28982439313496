import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { IntlProvider } from "react-intl";
import { SearchProvider, useSearchContext } from "./context/SearchContext";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import JobList from "./components/JobList/JobList";
import JobView from "./components/JobView/JobView";
import ConsultancyJobs from "./components/ConsultancyJobs/ConsultancyJobs";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import NotFound from "./components/NotFound/NotFound";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse/TermsOfUse";
import FAQs from "./components/Faq/FAQs";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { initGA, logPageView } from "./analytics";
import RouteTracker from "./components/analytics/RouteTracker/RouteTracker";
import axios from "axios";
import "./App.css";

// Import translation files
import enMessages from "./locales/en.json";
import frMessages from "./locales/fr.json";
import nyMessages from "./locales/ny.json";

const messages = {
  en: enMessages,
  ny: nyMessages,
  fr: frMessages,
};

function AppContent() {
  const [categories, setCategories] = useState([]);
  const { searchState, updateSearchState } = useSearchContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [locale, setLocale] = useState("en"); // Default language

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const newSearchTerm = searchParams.get("search") || "";
    const newSelectedCategory = searchParams.get("category") || "";

    updateSearchState({
      searchTerm: newSearchTerm,
      selectedCategory: newSelectedCategory,
    });

    if (
      location.pathname === "/" ||
      location.pathname === "/consultancies" ||
      newSearchTerm ||
      newSelectedCategory
    ) {
      updateSearchState({
        lastSearchPage: location.pathname + location.search,
      });
    }
  }, [location.search, location.pathname, updateSearchState]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/categories/`
      );
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (category) => {
    updateSearchState({ selectedCategory: category });
    const searchParams = new URLSearchParams(location.search);
    if (category) {
      searchParams.set("category", category);
    } else {
      searchParams.delete("category");
    }

    if (location.pathname !== "/") {
      navigate(`/?${searchParams.toString()}`);
    } else {
      navigate(`${location.pathname}?${searchParams.toString()}`);
    }
  };

  const handleSearch = (term) => {
    updateSearchState({ searchTerm: term });
    const searchParams = new URLSearchParams(location.search);
    if (term) {
      searchParams.set("search", term);
    } else {
      searchParams.delete("search");
    }

    navigate(`/?${searchParams.toString()}`);
  };

  const handleResetSearchAndFilter = () => {
    updateSearchState({ searchTerm: "", selectedCategory: "" });
    navigate(location.pathname);
  };

  const handleLanguageChange = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <IntlProvider messages={messages[locale]} locale={locale}>
      <div className="App">
        <ScrollToTop />
        <Header
          categories={categories}
          onCategoryChange={handleCategoryChange}
          onSearch={handleSearch}
          searchTerm={searchState.searchTerm}
          selectedCategory={searchState.selectedCategory}
          onResetSearchAndFilter={handleResetSearchAndFilter}
          onLanguageChange={handleLanguageChange}
          currentLocale={locale}
        />
        <main>
          <Routes>
            <Route path="/" element={<JobList />} />
            <Route path="/jobs/:id" element={<JobView />} />
            <Route path="/consultancies" element={<ConsultancyJobs />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsOfUse />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </IntlProvider>
  );
}

function App() {
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  useEffect(() => {
    initGA(trackingId);
    logPageView();
  }, [trackingId]);

  return (
    <ErrorBoundary>
      <Router>
        <RouteTracker />
        <SearchProvider>
          <AppContent />
        </SearchProvider>
        <RouteTracker />
      </Router>
    </ErrorBoundary>
  );
}

export default App;
