import React from "react";
import { formatDistanceToNow } from "date-fns";
import "./JobCard.css";

function JobCard({ job }) {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getTimeAgo = (dateString) => {
    return formatDistanceToNow(new Date(dateString), { addSuffix: true });
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div className="JobCard">
      <div className="job-info">
        <h3>{truncate(job.title, 50)}</h3>
        <p className="company">{truncate(job.company, 30)}</p>
        <p className="location">{job.location}</p>
        {job.salary && <p className="salary">{job.salary}</p>}
        <p className="hours-contract">
          {job.hours} | {job.contract_type}
        </p>
        <p className="deadline">
          <span className="calendar-icon">📅</span>
          Apply by:{" "}
          <strong>
            {job.deadline ? formatDate(job.deadline) : "Not specified"}
          </strong>
        </p>
        <p className="posted">Posted: {getTimeAgo(job.created_at)}</p>
      </div>
      <div className="job-tags">
        {job.tags &&
          job.tags.slice(0, 3).map((tag, index) => (
            <span key={index} className="tag">
              {tag.name}
            </span>
          ))}
      </div>
    </div>
  );
}

export default JobCard;
