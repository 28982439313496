// Header.js
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import "./Header.css";
import logo from "../../assets/logo.png";

function Header({
  categories,
  onCategoryChange,
  onSearch,
  searchTerm,
  selectedCategory,
  onResetSearchAndFilter,
  onLanguageChange,
  currentLocale,
}) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);
  const intl = useIntl();
  const menuRef = useRef(null);

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (mobileMenuOpen && menuRef.current) {
      const menuHeight = menuRef.current.offsetHeight;
      document.body.style.setProperty("--menu-height", `${menuHeight}px`);
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
    }

    return () => {
      document.body.classList.remove("menu-open");
    };
  }, [mobileMenuOpen]);

  const handleSearch = (e) => {
    e.preventDefault();
    onSearch(localSearchTerm);
    setMobileMenuOpen(false);
  };

  const handleSearchInputChange = (e) => {
    setLocalSearchTerm(e.target.value);
  };

  const handleCategoryChange = (e) => {
    onCategoryChange(e.target.value);
    setMobileMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleResetAndClose = () => {
    onResetSearchAndFilter();
    setMobileMenuOpen(false);
  };

  return (
    <header className="Header">
      <div className="header-content">
        <div className="logo">
          <Link to="/" onClick={handleResetAndClose}>
            <img src={logo} alt="Job Hunt Malawi Logo" />
          </Link>
        </div>
        <div className="mobile-controls">
          <div className="mobile-search-bar">
            <form onSubmit={handleSearch}>
              <input
                type="text"
                name="search"
                placeholder={intl.formatMessage({ id: "search.placeholder" })}
                value={localSearchTerm}
                onChange={handleSearchInputChange}
                aria-label={intl.formatMessage({ id: "search.ariaLabel" })}
              />
              <button type="submit">
                {intl.formatMessage({ id: "search.button" })}
              </button>
            </form>
          </div>
          <button
            className="mobile-menu-toggle"
            onClick={toggleMobileMenu}
            aria-label={intl.formatMessage({ id: "nav.toggleMenu" })}
          >
            {mobileMenuOpen ? "✕" : "☰"}
          </button>
        </div>
        <nav
          ref={menuRef}
          className={`nav-malawi ${mobileMenuOpen ? "mobile-open" : ""}`}
        >
          <ul>
            <li>
              <Link to="/" onClick={handleResetAndClose}>
                {intl.formatMessage({ id: "nav.home" })}
              </Link>
            </li>
            <li>
              <Link
                to="/consultancies"
                onClick={() => setMobileMenuOpen(false)}
              >
                {intl.formatMessage({ id: "nav.consultancies" })}
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={() => setMobileMenuOpen(false)}>
                {intl.formatMessage({ id: "nav.about" })}
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={() => setMobileMenuOpen(false)}>
                {intl.formatMessage({ id: "nav.contact" })}
              </Link>
            </li>
            <li className="category-filter">
              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                aria-label={intl.formatMessage({ id: "category.select" })}
              >
                <option value="">
                  {intl.formatMessage({ id: "category.all" })}
                </option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </li>
            <li className="language-switcher">
              <select
                value={currentLocale}
                onChange={(e) => onLanguageChange(e.target.value)}
                aria-label={intl.formatMessage({ id: "language.select" })}
              >
                <option value="en">English</option>
                <option value="ny">Chichewa</option>
                <option value="fr">Français</option>
              </select>
            </li>
            <li className="search-bar">
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  name="search"
                  placeholder={intl.formatMessage({ id: "search.placeholder" })}
                  value={localSearchTerm}
                  onChange={handleSearchInputChange}
                  aria-label={intl.formatMessage({ id: "search.ariaLabel" })}
                />
                <button type="submit">
                  {intl.formatMessage({ id: "search.button" })}
                </button>
              </form>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;
