// FAQs.js
import React, { useState } from "react";
import "./FAQs.css";

const faqData = [
  {
    question: "How do I search for jobs?",
    answer:
      "You can use the search bar at the top of the page to look for specific job titles, companies, or keywords. You can also browse jobs by category using the dropdown menu.",
  },
  {
    question: "How do I apply for a job?",
    answer:
      "To apply for a job, click on the job listing to view the full details. If you're interested, click the 'Apply' button and follow the instructions provided by the employer.",
  },
  {
    question: "Is it free to use Job Hunt Malawi?",
    answer:
      "Yes, Job Hunt Malawi is completely free for job seekers. You can search and apply for jobs without any charges.",
  },
  {
    question: "How often are new jobs posted?",
    answer:
      "New jobs are posted daily. We recommend checking back regularly or setting up job alerts to stay updated on the latest opportunities.",
  },
  {
    question: "Can I post my resume on the site?",
    answer:
      "Currently, we don't offer a resume posting service. However, you can apply directly to jobs that interest you.",
  },
];

function FAQs() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faqs-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-list">
        {faqData.map((faq, index) => (
          <div key={index} className="faq-item">
            <button
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <span className="faq-icon">
                {activeIndex === index ? "−" : "+"}
              </span>
            </button>
            {activeIndex === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQs;
