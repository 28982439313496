import React from "react";
import JobList from "../JobList/JobList";
import "./ConsultancyJobs.css";

function ConsultancyJobs() {
  return (
    <div className="consultancy-jobs-container">
      <h1 className="consultancy-jobs-title">Consultancy Opportunities</h1>
      <JobList isConsultancy={true} />
    </div>
  );
}

export default ConsultancyJobs;
