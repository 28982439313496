import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useSearchContext } from "../../context/SearchContext";
import JobCard from "../JobCard/JobCard";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import "./JobList.css";

function JobList({ isConsultancy }) {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const location = useLocation();
  const { searchState, updateSearchState } = useSearchContext();

  const API_URL = process.env.REACT_APP_API_URL;

  const fetchJobs = useCallback(
    async (url = `${API_URL}/api/jobs/`) => {
      try {
        setLoading(true);
        const params = new URLSearchParams(location.search);
        if (isConsultancy) params.append("is_consultancy", "true");

        const response = await axios.get(url, { params });
        setJobs((prevJobs) =>
          url === `${API_URL}/api/jobs/`
            ? response.data.results
            : [...prevJobs, ...response.data.results]
        );
        setNextPage(response.data.next);
        setError(null);
      } catch (err) {
        setError("Error loading data. Please try again later.");
        // console.error("Error fetching jobs:", err);
      } finally {
        setLoading(false);
      }
    },
    [API_URL, location.search, isConsultancy]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const urlSearchTerm = searchParams.get("search") || "";
    const urlCategory = searchParams.get("category") || "";

    // Update search state if URL params don't match current state
    if (
      urlSearchTerm !== searchState.searchTerm ||
      urlCategory !== searchState.selectedCategory
    ) {
      updateSearchState({
        searchTerm: urlSearchTerm,
        selectedCategory: urlCategory,
      });
    }

    fetchJobs();
  }, [
    location.search,
    isConsultancy,
    searchState,
    updateSearchState,
    fetchJobs,
  ]);

  const loadMore = () => {
    if (nextPage) {
      fetchJobs(nextPage);
    }
  };

  if (loading && jobs.length === 0) return <LoadingSpinner />;
  if (error)
    return (
      <div className="error">
        <h1> {error}</h1>
        <span style={{ "font-size": "100px" }}>&#128542;</span>
      </div>
    );
  if (jobs.length === 0)
    return (
      <div className="no-jobs">
        <h1>No jobs found.</h1>
        <span style={{ fontSize: "100px" }}>&#128546;</span>
        <p>Try adjusting your search criteria.</p>
        <Link to="/" className="home-link">
          Go back to homepage
        </Link>
      </div>
    );

  return (
    <div className="JobList">
      <h2>
        {isConsultancy ? "Consultancy Opportunities" : "Latest Job Openings"}
      </h2>
      <div className="job-grid">
        {jobs.map((job) => (
          <Link
            to={`/jobs/${job.id}`}
            key={job.id}
            className="job-link"
            state={{ from: location.pathname + location.search }}
          >
            <JobCard job={job} />
          </Link>
        ))}
      </div>
      {nextPage && (
        <div className="load-more-container">
          <button onClick={loadMore} className="load-more-btn">
            Load More
          </button>
        </div>
      )}
    </div>
  );
}

export default JobList;
