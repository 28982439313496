import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: 21/08/2024 </p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Job Hunt Malawi. We respect your privacy and are committed
          to protecting your personal data.
        </p>
      </section>

      <section>
        <h2>2. Data We Collect</h2>
        <ul>
          <li>Usage data (How you interact with our website)</li>
          <li>
            If you sign up for an account with us, we collect the following
            information:
            <ul>
              <li>
                Personal identification information (Name, email address, phone
                number, etc.)
              </li>
              <li>
                Professional information (Resume, job history, education, etc.)
              </li>
            </ul>
          </li>
        </ul>
      </section>

      <section>
        <h2>3. How We Use Your Data</h2>
        <p>We use your data to provide and improve our services, including:</p>
        <ul>
          <li>Matching you with relevant job opportunities</li>
          <li>Improving our website and services</li>
          <li>Communicating with you about our services</li>
        </ul>
      </section>

      <section>
        <h2>4. Data Protection</h2>
        <p>
          We implement a variety of security measures to maintain the safety of
          your personal information.
        </p>
      </section>

      <section>
        <h2>5. Your Rights</h2>
        <p>
          You have the right to access, update, or delete your personal
          information at any time.
        </p>
      </section>

      <section>
        <h2>6. Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at info@jobhuntmalawi.com.
        </p>
      </section>
    </div>
  );
}

export default PrivacyPolicy;
