import React from "react";
import "./TermsOfUse.css";

function TermsOfUse() {
  return (
    <div className="TermsOfUse">
      <h1>Terms of Use</h1>
      <div className="terms-content">
        <p>
          Welcome to Job Hunt Malawi. By using our website and services, you
          agree to comply with and be bound by the following terms and
          conditions of use. Please review these terms carefully.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using Job Hunt Malawi, you agree to these Terms of Use
          and all applicable laws and regulations. If you do not agree with any
          part of these terms, you may not use our services.
        </p>

        <h2>2. Use of Services</h2>
        <p>
          Job Hunt Malawi provides a platform for job seekers to search for
          employment opportunities and for employers to post job listings. Users
          must provide accurate and complete information when creating accounts
          or posting job listings.
        </p>

        <h2>3. User Responsibilities</h2>
        <p>
          Users are responsible for maintaining the confidentiality of their
          account information and for all activities that occur under their
          account. Users must not misuse the website by knowingly introducing
          viruses, trojans, worms, or other malicious content.
        </p>

        <h2>4. Intellectual Property</h2>
        <p>
          The content, organization, graphics, design, and other matters related
          to Job Hunt Malawi are protected under applicable copyrights and other
          proprietary laws. The copying, redistribution, use, or publication of
          any such matters or any part of the website is prohibited.
        </p>

        <h2>5. Limitation of Liability</h2>
        <p>
          Job Hunt Malawi shall not be liable for any damages arising out of or
          in connection with the use or inability to use our services. This
          includes, without limitation, damages for loss of profits, goodwill,
          use, data, or other intangible losses.
        </p>

        <h2>6. Changes to Terms</h2>
        <p>
          Job Hunt Malawi reserves the right to modify these Terms of Use at any
          time. We will notify users of any significant changes by posting a
          notice on our website. Continued use of Job Finder after such
          modifications will constitute acknowledgment and agreement of the
          modified terms.
        </p>

        <p className="last-updated">
          {/* Uses the retarded mm/dd/yyyy format.
            and for some reason the date is offset by one so 08/12 is actually August 11 not 12
            🤦‍♂️
           */}
          Last updated: {new Date("08/12/2024").toUTCString()}
        </p>
      </div>
    </div>
  );
}

export default TermsOfUse;
